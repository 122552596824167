import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Modal, InputGroup, Row, Col, ModalFooter } from "react-bootstrap";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import {useDropzone} from 'react-dropzone'

const UpcomingGoLiveModal = (props) => {

    const {getRootProps, getInputProps} = useDropzone()

    const [selectedDay, setSelectedDay] = useState(null);

    return (
        <>
            <Modal
                className="modal-dialog-center upcoming-go-live-modal"
                size="xl"
                centered
                show={props.upcomingGoLive}
                onHide={props.closeUpcomingGoLiveModal}
            >
                <Modal.Body>
                    <Form className="upcoming-go-live-form">
                    <Button className="modal-close" onClick={() => props.closeUpcomingGoLiveModal()}>
                        <Image
                            className="close-icon"
                            src={
                                window.location.origin + "/assets/images/new-chat/close.svg"
                            }
                        />
                    </Button>
                    <div className="upcoming-go-live-header-sec">
                        <h4>Go Live</h4>
                    </div>
                    <div className="upcoming-go-live-body-sec">
                        <Row className="justify-content-md-center">
                            <Col md={12} xl={4} lg={6} className="resp-marg-btm-xl">
                                <div className="upcoming-go-live-calander-sec">
                                    <Calendar
                                        value={selectedDay}
                                        onChange={setSelectedDay}
                                        calendarClassName="responsive-calendar" // added this
                                        shouldHighlightWeekends
                                    />
                                </div>
                            </Col>
                            <Col md={12} xl={4} lg={6} className="resp-marg-btm-xl">
                                <div className="upcoming-schedule-switch-sec">
                                    <div className="switch-toggle">
                                        <input type="checkbox" class="toggle" name="enabled" id="enabled"/>
                                        <label for="enabled">Schedule Live</label>
                                    </div>
                                </div>
                                <div className="time-zone-card">
                                    Time Zone (GMT+0) Europe/London  
                                </div>
                                <div className="time-picker-sec mb-4">
                                    <DatePicker
                                     render={<InputIcon />} 
                                        disableDayPicker
                                        format="HH:mm:ss"
                                        plugins={[
                                            <TimePicker />
                                        ]} 
                                        placeholder="SELECT TIME"
                                    />
                                </div>
                                <Form.Label>Privacy</Form.Label>
                                <Form.Group className="mb-4">
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="form-radio-card">
                                    <Form.Check
                                        inline
                                        label="Public"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Private"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                    />
                                    </div>
                                ))}
                                </Form.Group>
                                <Form.Label>Montize</Form.Label>
                                <Form.Group className="mb-4">
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="form-radio-card">
                                    <Form.Check
                                        inline
                                        label="Paid"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Free"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                    />
                                    </div>
                                ))}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tokens</Form.Label>
                                    <Form.Control type="email" placeholder="8963.23" />
                                </Form.Group>
                            </Col>
                            <Col md={12} xl={4} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Live title</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Here" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>Live Description (Optional)</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Here" />
                                </Form.Group>
                                <div className="upload-thumbanil-sec">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                       <div className="upload-thumbanil-card">
                                            <Image
                                                className="upload-thumbanil-img"
                                                src={
                                                window.location.origin + "/assets/images/schedule-availablity/dropzone-thumb.png"
                                                }
                                            />
                                            <p>Upload Your Thumnail image  Here, <Link to="#">Browse</Link></p>
                                       </div>
                                    </div>
                                </div>
                                <div className="modal-footer-video-btn-sec">
                                    <Button className="btn-cancel">
                                        Cancel
                                    </Button>
                                    <Button className="btn-proceed">
                                        Proceed
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UpcomingGoLiveModal;