import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  audioCallPaymentByRazorpayFailure,
  audioCallPaymentByRazorpaySuccess,
  chatAssetsPaymentByRazorpayFailure,
  chatAssetsPaymentByRazorpaySuccess,
  liveVideosPaymentByRazorpayFailure,
  liveVideosPaymentByRazorpaySuccess,
  ordersPaymentByRazorpayFailure,
  ordersPaymentByRazorpaySuccess,
  razorpayAddWalletMoneyFailure,
  razorpayAddWalletMoneySuccess,
  razorpayCreateOrderFailure,
  razorpayCreateOrderSuccess,
  subscriptionPaymentByRazorpayFailure,
  subscriptionPaymentByRazorpaySuccess,
  tipsPaymentByRazorpayFailure,
  tipsPaymentByRazorpaySuccess,
  videoCallPaymentByRazorpayFailure,
  videoCallPaymentByRazorpaySuccess
} from "../actions/RazorpayAction";
import {
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_START,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START,
  ORDERS_PAYMENT_BY_RAZORPAY_START,
  POST_PAYMENT_BY_RAZORPAY_START,
  RAZORPAY_ADD_WALLET_MONEY_START,
  RAZORPAY_CREATE_ORDER_START,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START,
  TIPS_PAYMENT_BY_RAZORPAY_START,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_START
} from "../actions/ActionConstant";
import { fetchSinglePostSuccess } from "../actions/PostAction";


function* razorpayCreateOrderAPI(action) {
  try {
    const response = yield api.postMethod("razorpay_create_order", action.data);
    if (response.data.success) {
      yield put(razorpayCreateOrderSuccess(response.data.data));
    } else {
      yield put(razorpayCreateOrderFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(response.data.error);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(razorpayCreateOrderFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* razorpayAddWalletMoneyAPI(action) {
  try {
    const response = yield api.postMethod("wallets_add_money_by_razorpay", action.data);
    if (response.data.success) {
      yield put(razorpayAddWalletMoneySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(response.data.message);
      yield put(createNotification(notificationMessage));
    } else {
      yield put(razorpayAddWalletMoneyFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(response.data.error);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(razorpayAddWalletMoneyFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* subscriptionPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("user_subscriptions_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(subscriptionPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(subscriptionPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(subscriptionPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* postPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("posts_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(subscriptionPaymentByRazorpaySuccess(response.data.data));
      yield put(fetchSinglePostSuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(subscriptionPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(subscriptionPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* tipsPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("tips_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(tipsPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(tipsPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(tipsPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* liveVideosPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(liveVideosPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(liveVideosPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(liveVideosPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* ordersPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("orders_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(ordersPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(ordersPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(ordersPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* chatAssetsPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("chat_assets_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(chatAssetsPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(chatAssetsPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(chatAssetsPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* videoCallPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("video_call_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(videoCallPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(videoCallPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(videoCallPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

function* audioCallPaymentByRazorpayAPI(action) {
  try {
    const response = yield api.postMethod("audio_call_payment_by_razorpay", action.data);
    if (response.data.success) {
      yield put(audioCallPaymentByRazorpaySuccess(response.data.data));
      yield put(createNotification(getSuccessNotificationMessage(response.data.message)));
    } else {
      yield put(audioCallPaymentByRazorpayFailure(response.data.error));
      yield put(createNotification(getErrorNotificationMessage(response.data.error)));
    }
  } catch (error) {
    yield put(audioCallPaymentByRazorpayFailure(error));
    yield put(createNotification(getErrorNotificationMessage(error.message)));
  }
}

export default function* RazorpaySaga() {
  yield all([
    yield takeLatest(RAZORPAY_CREATE_ORDER_START, razorpayCreateOrderAPI),
    yield takeLatest(RAZORPAY_ADD_WALLET_MONEY_START, razorpayAddWalletMoneyAPI),
    yield takeLatest(SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START, subscriptionPaymentByRazorpayAPI),
    yield takeLatest(POST_PAYMENT_BY_RAZORPAY_START, postPaymentByRazorpayAPI),
    yield takeLatest(TIPS_PAYMENT_BY_RAZORPAY_START, tipsPaymentByRazorpayAPI),
    yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START, liveVideosPaymentByRazorpayAPI),
    yield takeLatest(ORDERS_PAYMENT_BY_RAZORPAY_START, ordersPaymentByRazorpayAPI),
    yield takeLatest(CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START, chatAssetsPaymentByRazorpayAPI),
    yield takeLatest(VIDEO_CALL_PAYMENT_BY_RAZORPAY_START, videoCallPaymentByRazorpayAPI),
    yield takeLatest(AUDIO_CALL_PAYMENT_BY_RAZORPAY_START, audioCallPaymentByRazorpayAPI),
  ]);
}
