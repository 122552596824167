import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Image, Modal, InputGroup, Row, Col, ModalFooter } from "react-bootstrap";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import dayjs from 'dayjs';
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchTrainerAvailabilitesForUserStart } from "../../store/actions/AvailabilityAction";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import { requestCallStart, requestAudioCallStart } from "../../store/actions/PrivateCallAction";

const ScheduleAvailabilityRequestModal = (props) => {
	const history = useHistory();

	const currentDate = new Date(
		new Date().toLocaleString('en', { timeZone: props.profile.data.timezone })
	);
	const today = {
		year: currentDate.getFullYear(),
		month: parseInt(currentDate.getMonth() + 1),
		day: currentDate.getDate()
	};
	const [skipRender, setSkipRender] = useState(true);
	const [selectedDay, setSelectedDay] = useState(today);
	const [selectedStartEndTime, setSelectedStartEndTime] = useState(null);

	const formatNumber = (num) => {
		if (num < 10) return "0" + num;
		else return num;
	};

	useEffect(() => {
		setSelectedStartEndTime(null);
		let currentDate = dayjs(new Date())
			.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
			.format("YYYY-MM-DD HH:mm");

		props.dispatch(
			fetchTrainerAvailabilitesForUserStart({
				date: `${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)}`,
				trainer_id: props.user.user_id,
				user_time_now: currentDate,
			})
		);
	}, [selectedDay]);

	const handleSubmit = () => {
		const { start_time, end_time } = selectedStartEndTime;
		let scheduleDate = `${selectedDay.year}-${formatNumber(selectedDay.month)}-${formatNumber(selectedDay.day)}`;
		const data = {
			date: scheduleDate,
			start_time: start_time,
			end_time: end_time,
			model_id: props.user.user_id,
		};
		props.dispatch(props.type === "Video" ? requestCallStart(data) : requestAudioCallStart(data));
	}

	useEffect(() => {
		if (!skipRender && !props.requestCall.loading && Object.keys(props.requestCall.data).length > 0) {
			history.push("/video-calls-history");
		}
	}, [props.requestCall]);

	useEffect(() => {
		if (!skipRender && !props.requestAudioCall.loading && Object.keys(props.requestAudioCall.data).length > 0) {
			history.push("/audio-calls-history");
		}
		setSkipRender(false);
	}, [props.requestAudioCall]);

	return (
		<>
			<Modal
				className="modal-dialog-center schedule-availability-video-modal"
				size="lg"
				centered
				show={true}
				onHide={props.closeScheduleAvailabilityVideoModal}
			>
				<Modal.Body>
					<Button className="modal-close" onClick={() => props.closeScheduleAvailabilityVideoModal()}>
						<Image
							className="close-icon"
							src={
								window.location.origin + "/assets/images/new-chat/close.svg"
							}
						/>
					</Button>
					<div className="schedule-availability-video-header-sec">
						<h4>{props.type} Call</h4>
						<p>Placing a Video Call request would send a request to the Coach. The Coach would be able to accept/reject the Video Call request based on their availability</p>
					</div>
					<div className="schedule-availability-video-body-sec">
						<Row>
							<Col md={5} className="resp-marg-btm-xs">
								<div className="video-calander-sec">
									<Calendar
										value={selectedDay}
										onChange={setSelectedDay}
										calendarClassName="responsive-calendar" // added this
										shouldHighlightWeekends
										minimumDate={today}
									/>
								</div>
							</Col>
							<Col md={7}>
								{props.trainerAvailabilites.loading ?
									<CommonCenterLoader />
									: props.trainerAvailabilites.data.trainer_availabilities ?
										<>
											<div className="time-zone-card">
												Trainer Time Zone IS {props.user.timezone}
											</div>
											{props.trainerAvailabilites.data.trainer_availabilities.length > 0 ?
												<div className="time-select-box">
													{props.trainerAvailabilites.data.trainer_availabilities.map((trainer_slot, i) =>
														<div className="time-select-card" key={i}>
															<Button
																disabled={trainer_slot.is_slot_booked == 1}
																className={`
																time-btn 
																${trainer_slot === selectedStartEndTime ? "selected" : ""}
																${trainer_slot.is_slot_booked == 1 ? "strike-off" : ""}
																`}
																onClick={() => {
																	if (trainer_slot.is_slot_booked == 0) {
																		setSelectedStartEndTime(trainer_slot)
																	}
																}}
															>
																{trainer_slot.start_time_for_user} - {trainer_slot.end_time_for_user}
															</Button>
														</div>
													)}
												</div>
												: <NoDataFound />
											}
										</>
										: <Image
											className="w-100"
											src={window.location.origin + "/assets/images/new-chat/something-went-wrong.png"}
										/>
								}
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<div className="modal-footer-video-sec">
									<h4>
										{props.type === "Video"
											? props.user.video_call_amount_formatted
											: props.user.audio_call_amount_formatted
										}/Hour
									</h4>
									<div className="modal-footer-video-btn-sec">
										<Button className="btn-cancel" onClick={() => props.closeScheduleAvailabilityVideoModal()}>
											Cancel
										</Button>
										<Button
											className="btn-proceed"
											onClick={() => handleSubmit()}
											disabled={
												!selectedDay ||
												!selectedStartEndTime ||
												props.requestCall.buttonDisable ||
												props.requestAudioCall.buttonDisable
											}
										>
											{props.requestCall.buttonDisable ||
												props.requestAudioCall.buttonDisable ?
												t("loading")
												: "Proceed"
											}
										</Button>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	profile: state.users.profile,
	trainerAvailabilites: state.availability.trainerAvailabilites,
	requestCall: state.privateCall.requestCall,
	requestAudioCall: state.privateCall.requestAudioCall,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(ScheduleAvailabilityRequestModal));