import React, { useEffect, useRef, useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, Media, Dropdown, InputGroup } from "react-bootstrap";

const BroadcastChat = ({ message, isSent }) => {

  return <>
    {/* <div className="broadcast-chat-container">
      <div className='broadcast-chat'>
        <h6>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4" clip-path="url(#clip0_79_10893)">
              <path d="M12.599 3.3107L13.6032 2.30654C13.7863 2.12348 13.7863 1.8267 13.6032 1.64363C13.4202 1.46057 13.1234 1.4606 12.9403 1.64363L11.9361 2.64779C11.7531 2.83082 11.7531 3.12763 11.9361 3.3107C12.1191 3.49373 12.4159 3.49379 12.599 3.3107Z" fill="currentColor" />
              <path d="M14.7781 5.52295H13.7739C13.5151 5.52295 13.3052 5.73282 13.3052 5.9917C13.3052 6.25057 13.5151 6.46045 13.7739 6.46045H14.7781C15.037 6.46045 15.2469 6.25057 15.2469 5.9917C15.2469 5.73282 15.037 5.52295 14.7781 5.52295Z" fill="currentColor" />
              <path d="M9.25488 1.94166C9.51376 1.94166 9.72363 1.73178 9.72363 1.47291V0.46875C9.72363 0.209875 9.51376 0 9.25488 0C8.99601 0 8.78613 0.209875 8.78613 0.46875V1.47291C8.78613 1.73178 8.99601 1.94166 9.25488 1.94166Z" fill="currentColor" />
              <path d="M0.890168 11.6853C0.707262 11.8682 0.707105 12.1652 0.890168 12.3482L2.89851 14.3566C3.08151 14.5396 3.37832 14.5397 3.56142 14.3566L4.34692 13.5711L1.6757 10.8999L0.890168 11.6853Z" fill="currentColor" />
              <path d="M9.00582 12.0635L5.71436 13.6124L7.96445 15.8625C8.14745 16.0455 8.44429 16.0456 8.62736 15.8625L10.3846 14.1052C10.5677 13.9222 10.5677 13.6254 10.3846 13.4423L9.00582 12.0635Z" fill="currentColor" />
              <path d="M7.07582 1.64364C6.96788 1.5357 6.81413 1.48711 6.66375 1.51332C6.51338 1.53957 6.38522 1.63739 6.32022 1.77551L2.33838 10.2369L5.0096 12.9081L13.471 8.92629C13.6091 8.86129 13.7069 8.73311 13.7332 8.58273C13.7594 8.43236 13.7108 8.27864 13.6029 8.1707L7.07582 1.64364Z" fill="currentColor" />
            </g>
            <defs>
              <clipPath id="clip0_79_10893">
                <rect width="16" height="16" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
          <span>{isSent ? "You" : message.from_displayname}</span> : {message.message}
        </h6>
      </div>
    </div> */}
  </>
}

export default BroadcastChat;