import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import "./ScheduleAvailability.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { CalenderScheduler } from "../CustomComponents/CalenderScheduler/CalenderScheduler";
import { fetchTrainerAvailabilitesStart, saveTrainerAvailabilitesStart } from '../../store/actions/AvailabilityAction';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { translate, t } from 'react-multi-lang';

const ScheduleAvailabilityIndex = (props) => {
	const history = useHistory();

	const [today, setToday] = useState(null);

	const formatNumber = (num) => {
		if (num < 10) return "0" + num;
		else return num;
	};

	const [skipRender, setSkipRender] = useState(true);
	const [startTime, setStartTime] = useState();
	const [startTimeFormatted, setStartTimeFormatted] = useState();
	const [selectedSlots, setSelectedSlots] = useState([]);

	useEffect(() => {
		if (!skipRender) {
			props.dispatch(fetchTrainerAvailabilitesStart({ date: startTime }));
		}
	}, [startTime]);

	useEffect(() => {
		if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
			setToday(new Date(new Date().toLocaleString('en', { timeZone: props.profile.data.timezone })));
		}
	}, [props.profile]);

	useEffect(() => {
		if (today) {
			setStartTime(today.getFullYear() +
				"-" +
				formatNumber(parseInt(today.getMonth() + 1)) +
				"-" +
				formatNumber(today.getDate()));
			setStartTimeFormatted(parseInt(today.getMonth() + 1) +
				"/" +
				today.getDate() +
				"/" +
				today.getFullYear());
		}
	}, [today]);

	const startTimeOnchange = (time) => {
		setSelectedSlots([]);
		console.log(time);
		// setStartTime(dayjs(time).format("YYYY-MM-DD"));
		setStartTime(time);
		setStartTimeFormatted(dayjs(time).format("MM/DD/YYYY"));
	};

	const onClickTimeslot = (timeslot) => {
		if (selectedSlots.includes(timeslot)) {
			setSelectedSlots(
				selectedSlots.filter((x) => x.slot_id !== timeslot.slot_id)
			);
		} else {
			setSelectedSlots([...selectedSlots, timeslot]);
		}
	};

	const selectAllTimeSlots = () => setSelectedSlots(props.availability.data.trainer_slot);
	const unselectAllTimeSlots = () => setSelectedSlots(
		props.availability.data.trainer_slot.filter((x) => x.is_slot_booked == 1)
	);

	const handleSubmit = (event) => {
		console.log(JSON.stringify(selectedSlots));
		event.preventDefault();
		const removedSlots = props.availability.data.trainer_slot.filter(slot => slot.is_available == 1)
			.filter(oldSlot =>
				!selectedSlots.some(selectedSlot =>
					oldSlot.slot_id === selectedSlot.slot_id
				)
			);
		props.dispatch(
			saveTrainerAvailabilitesStart({
				date: startTime,
				slots: JSON.stringify(selectedSlots),
				removed_slots: JSON.stringify(removedSlots),
			})
		);
	};

	useEffect(() => {
		if (!skipRender && !props.availability.loading && Object.keys(props.availability.data).length > 0) {
			setSelectedSlots(props.availability.data.trainer_slot.filter((x) => x.is_available == 1 || x.is_slot_booked == 1));
		}
		setSkipRender(false);
	}, [props.availability]);


	return (
		<>
			<div className="schedule-availablity-sec">
				<div className="schedule-availablity-header-sec">
					<Container>
						<Row>
							<Col sm={12} md={12}>
								<div className="schedule-availablity-header-card">
									<Link
										className=""
										onClick={() => props.history.goBack()}
									>
										<img
											src={
												window.location.origin +
												"/assets/images/icons/back-white.svg"
											}
											className="svg-clone"
										/>
									</Link>
									<div className="schedule-availablity-header-info">
										<h3>Set Availability</h3>
										<p>This list contains Audio Call requests which are sent by you to other users and requests that you have received from other users.</p>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				{today ?
					<div className="schedule-availability-body-sec">
						<Container>
							<div className="schedule-availability-box">
								<Row>
									<Col xl={8} md={12} className="resp-marg-btm-xl resp-marg-btm-xs">
										<div className="schedule-availability-calendar-box">
											{/* <Calendar
											value={selectedDay}
											onChange={setSelectedDay}
											calendarClassName="responsive-calendar" // added this
											shouldHighlightWeekends
										/> */}
											<CalenderScheduler
												date={today}
												format="YYYY-MM-DD"
												disablePastDate={true}
												onChange={startTimeOnchange}
											/>
										</div>
									</Col>
									<Col xl={4} md={12}>
										<div className="schedule-availability-select-box">
											<div className="schedule-availability-select-header-sec">
												<h3 className="mb-2">Schedule Availability</h3>
												{/* <p>December 26, Sunday</p> */}
											</div>
											<div className="schedule-availability-select-card">
												<div className="schedule-availability-select-top-sec">
													<h4>{startTimeFormatted}</h4>
													{props.availability.data.trainer_slot?.length > 0 ?
														<div className="schedule-availability-select-all-checkbox">
															<input
																type="checkbox"
																id="todo"
																name="todo"
																value="todo"
																checked={props.availability.data.trainer_slot?.length === selectedSlots.length}
																onClick={() => {
																	if (props.availability.data.trainer_slot?.length === selectedSlots.length)
																		unselectAllTimeSlots()
																	else
																		selectAllTimeSlots()
																}}
															/>
															<label for="todo" data-content="Get out of bed">
																Mark All Available
															</label>
														</div>
														: null
													}
												</div>

												<div
													className="schedule-availability-select-middle-sec"
													style={{
														height: "calc(100vh - 350px)",
														overflowY: "auto",
														marginTop: "1em",
													}}
												>
													<ul className="list-unstyled schedule-availability-select-list">
														{props.availability.data.trainer_slot?.map((time_slot, i) =>
															<li
																key={i}
																className={
																	time_slot.is_slot_booked
																		? "booked-status"
																		: time_slot.is_available
																			? ""
																			: "not-available-status"}
															// className="not-available-status"
															>
																<label
																	for={`radio-card-${i}`}
																	className="radio-card"
																>
																	<input
																		type="checkbox"
																		id={`radio-card-${i}`}
																		onChange={(e) =>
																			onClickTimeslot(time_slot)
																		}
																		disabled={time_slot.is_slot_booked}
																		checked={selectedSlots.includes(time_slot)}
																	/>
																	<div className="card-content-wrapper">
																		<div className="schedule-availability-time">
																			<span>
																				{time_slot.start_time}
																			</span>
																			<span>-</span>
																			<span>{time_slot.end_time}</span>
																		</div>
																		<div className="schedule-availability-status-tick">
																			<span className="check-icon"></span>
																			<h4>
																				{time_slot.is_slot_booked
																					? "Booked"
																					: props.availability.data.is_new_slot
																						? "Available"
																						: time_slot.is_available
																							? "Available"
																							: "Not Available"}
																			</h4>
																		</div>
																	</div>
																</label>
															</li>
														)}
													</ul>
												</div>
												<div className="schedule-availability-select-bottom-sec">
													<div className="please-be-available-sec">
														<h5>
															<Image
																className="warning-icon"
																src={
																	window.location.origin +
																	"/assets/images/schedule-availablity/warning.svg"
																}
															/>
															<span>Please be available</span>
														</h5>
													</div>
													<div className="schedule-availability-select-btn-sec">
														<Button
															className="about-me-btn"
															onClick={handleSubmit}
															disabled={props.saveAvailability.buttonDisable}
														>
															{props.saveAvailability.loadingButtonContent !==
																null
																? props.saveAvailability.loadingButtonContent
																: t("submit")}
														</Button>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
					: null
				}
			</div >
		</>
	)
}

const mapStateToPros = (state) => ({
	availability: state.availability.trainerAvailabilites,
	saveAvailability: state.availability.saveTrainerAvailability,
	profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(ScheduleAvailabilityIndex));