import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchTrainerAvailabilitesFailure,
  fetchTrainerAvailabilitesForUserFailure,
  fetchTrainerAvailabilitesForUserSuccess,
  fetchTrainerAvailabilitesSuccess,
  saveTrainerAvailabilitesFailure,
  saveTrainerAvailabilitesSuccess,
} from "../actions/AvailabilityAction";
import {
  FETCH_TRAINER_AVAILABILITES_START,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_START,
  SAVE_TRAINER_AVAILABILITES_START
} from "../actions/ActionConstant";


function* fetchTrainerAvailabilitesAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities", action.data);
    if (response.data.success) {
      yield put(fetchTrainerAvailabilitesSuccess(response.data.data));
    } else {
      yield put(fetchTrainerAvailabilitesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(response.data.error);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrainerAvailabilitesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveTrainerAvailabilitesAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities_save", action.data);
    if (response.data.success) {
      yield put(saveTrainerAvailabilitesSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(response.data.message);
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveTrainerAvailabilitesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(response.data.error);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveTrainerAvailabilitesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTrainerAvailablitiesForUserAPI(action) {
  try {
    const response = yield api.postMethod("trainer_availabilities_for_user", action.data);
    if (response.data.success) {
      yield put(fetchTrainerAvailabilitesForUserSuccess(response.data.data));
    } else {
      yield put(fetchTrainerAvailabilitesForUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(response.data.error);
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrainerAvailabilitesForUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}



export default function* AvailabilitySaga() {
  yield all([
    yield takeLatest(FETCH_TRAINER_AVAILABILITES_START, fetchTrainerAvailabilitesAPI),
    yield takeLatest(SAVE_TRAINER_AVAILABILITES_START, saveTrainerAvailabilitesAPI),
    yield takeLatest(FETCH_TRAINER_AVAILABILITIES_FOR_USER_START, fetchTrainerAvailablitiesForUserAPI),
  ]);
}