import React, { useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useState } from "react";
import AddCardModalSec from "./AddCardModalSec";
import PaymentMethodCard from "./PaymentMethodCard";
import PaymentModelMsgSec from "./PaymentModelMsgSec";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import {
  livePaymentPaypalStart,
  livePaymentStripeStart,
  livePaymentWalletStart,
} from "../../../store/actions/LiveVideoAction";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { liveVideosPaymentByRazorpayStart, razorpayCreateOrderStart } from "../../../store/actions/RazorpayAction";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const LiveStreamingPaymentModal = (props) => {
  const history = useHistory();

  const nullData = ["", null, undefined, "light"];

  const [skipRender, setSkipRender] = useState(true);
  const [paymentType, setPaymentType] = useState(localStorage.getItem("default_payment_method"));
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        livePaymentPaypalStart({
          live_video_id: props.live.live_video_id,
          payment_id: payment.paymentID,
        })
      );
    }, 1000);
    props.closePaymentModal();
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const handleSubmit = async () => {
    if (paymentType === "CARD") {
      props.dispatch(
        livePaymentStripeStart({
          live_video_id: props.live.live_video_id,
          user_card_id: selectedCard,
        })
      );
      props.closePaymentModal();
    }
    if (paymentType === "WALLET") {
      props.dispatch(
        livePaymentWalletStart({
          live_video_id: props.live.live_video_id,
        })
      );
      props.closePaymentModal();
    }
    if (paymentType === "RAZOR_PAY") {
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
      } else {
        props.dispatch(razorpayCreateOrderStart({
          amount: props.live.amount
        }));
      }
    }
  };

  useEffect(() => {
    if (!skipRender && !props.initilizeRazorpay.loading && Object.keys(props.initilizeRazorpay.data).length > 0) {
      const options = {
        key: configuration.get("configData.RAZOR_KEY"),
        currency: props.initilizeRazorpay.data.currency,
        amount: props.initilizeRazorpay.data.amount.toString(),
        order_id: props.initilizeRazorpay.data.id,
        name: 'Subscription',
        description: '',
        handler: async (response) => {
          try {
            const payment_id = response.razorpay_payment_id;
            props.dispatch(liveVideosPaymentByRazorpayStart({
              live_video_id: props.live.live_video_id,
              payment_id: payment_id,
            }));
          } catch (err) {
            console.log(err);
          }
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [props.initilizeRazorpay]);

  useEffect(() => {
    if (!skipRender && !props.liveRazorpay.loading && Object.keys(props.liveRazorpay.data).length > 0) {
      history.push(`/live-video/${props.liveRazorpay.data.live_video_unique_id}`);
    }
    setSkipRender(false);
  }, [props.liveRazorpay]);

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
            }`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Body className="wallet-card-body">
            <Button className="modal-close"
              onClick={() => props.closepaymentsModal()}>
              <i className="fa fa-times" />
            </Button>
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                />
                <Col md={12} xl={5}>
                  {showAddCard ?
                    <AddCardModalSec
                      setShowAddCard={setShowAddCard}
                    />
                    : <PaymentModelMsgSec
                      title={props.live.title}
                      message={props.live.description}
                      paymentType={paymentType}
                      amount_formatted={props.live.amount_formatted}
                      amount={props.live.amount}
                      payNowAction={handleSubmit}
                      paypalOnError={paypalOnError}
                      paypalOnSuccess={paypalOnSuccess}
                      paypalOnCancel={paypalOnCancel}
                      btnDisable={
                        props.liveVideoDetails.buttonDisable ||
                        props.initilizeRazorpay.buttonDisable ||
                        props.liveRazorpay.buttonDisable
                      }
                      btnText={props.liveVideoDetails.loadingButtonContent
                        ? props.liveVideoDetails.loadingButtonContent
                        : props.initilizeRazorpay.loadingButtonContent
                          ? props.initilizeRazorpay.loadingButtonContent
                          : props.liveRazorpay.loadingButtonContent
                            ? props.liveRazorpay.loadingButtonContent
                            : t("pay")}
                    />
                  }
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideoDetails: state.liveVideo.singleLiveVideo,
  initilizeRazorpay: state.razorPay.initilizeRazorpay,
  liveRazorpay: state.razorPay.liveRazorpay,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveStreamingPaymentModal));
