import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import PaymentMethodCard from "./PaymentMethodCard";
import AddCardModalSec from "./AddCardModalSec";
import PaymentModelMsgSec from "./PaymentModelMsgSec";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import {
  subscriptionPaymentPaypalStart,
  subscriptionPaymentStripeStart,
  subscriptionPaymentWalletStart,
} from "../../../store/actions/SubscriptionAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
  razorpayCreateOrderStart,
  subscriptionPaymentByRazorpayStart
} from "../../../store/actions/RazorpayAction";
import { fetchSingleUserProfileStart } from "../../../store/actions/OtherUserAction";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const SubscriptionPaymentModal = (props) => {
  const nullData = ["", null, undefined, "light"];
  const [skipRender, setSkipRender] = useState(true);

  const [paymentType, setPaymentType] = useState(
    localStorage.getItem("default_payment_method")
  );
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      subscriptionPaymentPaypalStart({
        payment_id: payment.paymentID,
        user_unique_id: props.user_unique_id,
        plan_type: props.subscriptionData.plan_type,
        is_free: props.subscriptionData.is_free,
      });
    }, 1000);
    props.closePPVPaymentModal();
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const handleSubmit = async () => {
    if (paymentType === "CARD")
      props.dispatch(
        subscriptionPaymentStripeStart({
          user_unique_id: props.user_unique_id,
          plan_type: props.subscriptionData.plan_type,
          is_free: props.subscriptionData.is_free,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        subscriptionPaymentWalletStart({
          user_unique_id: props.user_unique_id,
          plan_type: props.subscriptionData.plan_type,
          is_free: props.subscriptionData.is_free,
        })
      );
    if (paymentType === "RAZOR_PAY") {
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
      } else {
        props.dispatch(razorpayCreateOrderStart({
          amount: props.subscriptionData.amount
        }));
      }
    }
  };

  useEffect(() => {
    if (!skipRender && !props.initilizeRazorpay.loading && Object.keys(props.initilizeRazorpay.data).length > 0) {
      const options = {
        key: configuration.get("configData.RAZOR_KEY"),
        currency: props.initilizeRazorpay.data.currency,
        amount: props.initilizeRazorpay.data.amount.toString(),
        order_id: props.initilizeRazorpay.data.id,
        name: 'Subscription',
        description: '',
        handler: async (response) => {
          try {
            const payment_id = response.razorpay_payment_id;
            props.dispatch(subscriptionPaymentByRazorpayStart({
              payment_id: payment_id,
              user_unique_id: props.user_unique_id,
              plan_type: props.subscriptionData.plan_type,
              is_free: props.subscriptionData.is_free,
              amount: props.subscriptionData.amount
            }));
          } catch (err) {
            console.log(err);
          }
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [props.initilizeRazorpay]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.subPayStripe.loading &&
      Object.keys(props.subPayStripe.success).length > 0
    ) {
      props.closepaymentsModal();
    }
  }, [props.subPayStripe]);

  useEffect(() => {
    if (!skipRender && !props.subscriptionRazorpay.loading && Object.keys(props.subscriptionRazorpay.data).length > 0) {
      props.dispatch(fetchSingleUserProfileStart({
        user_unique_id: props.user_unique_id,
      }))
      props.closepaymentsModal();
    }
  })

  useEffect(() => {
    if (
      !skipRender &&
      !props.subPayWallet.loading &&
      Object.keys(props.subPayWallet.success).length > 0
    ) {
      props.closepaymentsModal();
    }
    setSkipRender(false);
  }, [props.subPayWallet]);

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme"))
            ? ""
            : "dark-theme-modal"
            }`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Body className="wallet-card-body">
            <Button
              className="modal-close"
              onClick={() => props.closepaymentsModal()}
            >
              <i className="fa fa-times" />
            </Button>
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                />
                <Col md={12} xl={5}>
                  {showAddCard ? (
                    <AddCardModalSec setShowAddCard={setShowAddCard} />
                  ) : (
                    <PaymentModelMsgSec
                      title={t("subscription_payment")}
                      message={t("subscription_payment_note")}
                      paymentType={paymentType}
                      amount_formatted={props.subscriptionData.amount_formatted}
                      amount={props.subscriptionData.amount}
                      payNowAction={handleSubmit}
                      paypalOnError={paypalOnError}
                      paypalOnSuccess={paypalOnSuccess}
                      paypalOnCancel={paypalOnCancel}
                      btnDisable={
                        props.subPayStripe.ButtonDisable ||
                        props.subPayWallet.ButtonDisable ||
                        props.initilizeRazorpay.buttonDisable ||
                        props.subscriptionRazorpay.buttonDisable
                      }
                      btnText={
                        props.subPayStripe.loadingButtonContent
                          ? props.subPayStripe.loadingButtonContnet
                          : props.subPayWallet.loadingButtonContent
                            ? props.subPayWallet.loadingButtonContent
                            : props.initilizeRazorpay.loadingButtonContent ?
                              props.initilizeRazorpay.loadingButtonContent
                              : props.subscriptionRazorpay.loadingButtonContent ?
                                props.subscriptionRazorpay.loadingButtonContent
                                : t("pay")
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subPayStripe: state.subscriptions.subPayStripe,
  subPayWallet: state.subscriptions.subPayWallet,
  initilizeRazorpay: state.razorPay.initilizeRazorpay,
  subscriptionRazorpay: state.razorPay.subscriptionRazorpay,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SubscriptionPaymentModal));
