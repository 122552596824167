import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import {
    Button,
    Container,
    Row,
    Col,
    Table,
    Badge,
    Image,
} from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import GoLiveModal from "../helper/GoLiveModal";
import {
    fetchLiveVideosScheduledStart,
    fetchMoreLiveVideosScheduledStart,
    liveVideoStartCallStart,
} from "../../store/actions/LiveVideoAction";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import "../Wallet/Wallet.css";
import LiveStreamingDataCard from "../LiveStreaming/LiveStreamingDataCard";
import LiveStreamingLoader from "../Loader/LiveStreamingLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const ScheduledLiveVideosIndex = (props) => {
    const [goLive, setGoLive] = useState(false);

    useEffect(() => {
        if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
        props.dispatch(
            fetchLiveVideosScheduledStart({
                skip: 0,
                take: 12,
            })
        );
    }, []);

    const closeGoLiveModal = () => {
        setGoLive(false);
    };

    const fetchMoreLiveVideoScheduleHistory = () => {
        props.dispatch(
            fetchMoreLiveVideosScheduledStart({
                skip: props.scheduledLive.data.live_videos.length,
                take: 12,
            })
        );
    };

    const handleLiveStart = (event, live_video_id) => {
        event.preventDefault();
        props.dispatch(
            liveVideoStartCallStart({
                live_video_id: live_video_id,
            })
        );
    };

    return (
        <>
            <div className="live-streaming-sec">
                <div className="live-streaming-header-sec">
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className="live-streaming-header-sec">
                                    {props.userDetails.loading ? (
                                        t("loading")
                                    ) : props.userDetails.data.is_content_creator === 2 ? (
                                        <div className="live-streaming-left-sec">
                                            <Link to="/live-videos" className="new-live-history-btn">
                                                {t("onlive")}
                                            </Link>
                                            <Link to="/scheduled-live" className="new-live-history-btn">
                                                {t("scheduled_live")}
                                            </Link>
                                            <Link to="/scheduled-live-videos" className="new-live-history-btn">
                                                {t("my_scheduled_live")}
                                            </Link>
                                            <Link
                                                to="/live-videos-history"
                                                className="new-live-history-btn pull-right"
                                            >
                                                {t("my_live_streams")}
                                            </Link>
                                            <Link
                                                to="#"
                                                className="new-go-live-btn pull-right"
                                                onClick={() => setGoLive(true)}
                                            >
                                                {t("go_live")}
                                            </Link>
                                        </div>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    {props.scheduledLive.loading ? (
                        <LiveStreamingLoader />
                    ) : props.scheduledLive.data.live_videos &&
                        props.scheduledLive.data.live_videos.length > 0 ? (
                        <>
                            <div className="most-popular-header-sec">
                                <h4>My Scheduled Live</h4>
                            </div>
                            <InfiniteScroll
                                dataLength={props.scheduledLive.data.live_videos.length}
                                next={fetchMoreLiveVideoScheduleHistory}
                                hasMore={
                                    props.scheduledLive.data.live_videos.length <
                                    props.scheduledLive.data.total
                                }
                                loader={<LiveStreamingLoader />}
                                style={{ height: "auto", overflow: "hidden" }}
                            >
                                <div className="most-popular-live-box">
                                    {props.scheduledLive.data.live_videos.map((live, index) => (
                                        <div className="most-popular-live-card" key={index}>
                                            <div className="most-popular-thumbnail-img-sec">
                                                <Image
                                                    className="most-popular-thumbnail-img"
                                                    src={live.snapshot}
                                                />
                                            </div>

                                            <div className="most-popular-user-info-card">
                                                <Link to={`/${live.username}`} className="most-popular-user-name">
                                                    <div className="most-popular-user-info">
                                                        <Image
                                                            className="most-popular-user-img"
                                                            src={live.user_picture}
                                                        />

                                                        <div className="most-popular-user-details">
                                                            <h4>
                                                                {live.user_displayname}
                                                                <span>
                                                                    <Image
                                                                        className="sidebar-verified-icon"
                                                                        src={
                                                                            window.location.origin +
                                                                            "/assets/images/new-home/verified-icon.png"
                                                                        }
                                                                    />
                                                                </span>
                                                            </h4>
                                                            <span className="most-popular-user-name">
                                                                @{live.username}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="most-popular-user-btn-sec">
                                                    {live.streaming_type == 2 &&
                                                        live.user_id == localStorage.getItem("userId") ? (
                                                        <Button
                                                            className="join-now-btn"
                                                            onClick={(event) =>
                                                                handleLiveStart(event, live.live_video_id)
                                                            }
                                                        >
                                                            {t("start_now")}
                                                        </Button>
                                                    ) : live.is_streaming ? (
                                                        <Link
                                                            className="join-now-btn"
                                                            to={`/join-live/${live.live_video_unique_id}`}
                                                        >
                                                            {t("join_now")}
                                                        </Link>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="most-popular-product-info">
                                                <h3>{live.title}</h3>
                                            </div>
                                            {live.streaming_type == 2 ?
                                                <div className="live-status-btn">
                                                    {live.formatted_schedule_time}
                                                </div>
                                                : ''}
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </>
                    ) : (
                        <NoDataFound />
                    )}
                </Container>
            </div>
            {props.userDetails.loading ? (
                t("loading")
            ) : (
                <GoLiveModal
                    goLive={goLive}
                    closeGoLiveModal={closeGoLiveModal}
                    username={props.userDetails.data.username}
                    userPicture={props.userDetails.data.picture}
                    name={props.userDetails.data.name}
                    user_id={props.userDetails.data.user_id}
                />
            )}
        </>
    );
};

const mapStateToPros = (state) => ({
    scheduledLive: state.liveVideo.scheduledLive,
    userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ScheduledLiveVideosIndex));
