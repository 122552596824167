import React, { useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { changeChatAudio } from '../../store/actions/ChatAction';
import AltraChatAudioPlayer from '../CustomComponents/AudioPlayer/AltraChatAudioPlayer';
import CustomLazyLoad from '../helper/CustomLazyLoad';

const SendChat = ({ message }) => {
  return (
    <>
      <div className="new-chat-room-right-sec">
        <div className="new-chat-room-right-msg-card">
          <div className="new-chat-room-right-msg-container">
            {message.broadcast_type == 1 ? 
              <div className="broad-cast-msg-highlight-sec">
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_412_2336)">
                <path d="M12.5993 3.3107L13.6035 2.30654C13.7865 2.12348 13.7865 1.8267 13.6035 1.64363C13.4204 1.46057 13.1236 1.4606 12.9406 1.64363L11.9364 2.64779C11.7533 2.83082 11.7533 3.12763 11.9364 3.3107C12.1194 3.49373 12.4162 3.49379 12.5993 3.3107Z" fill="url(#paint0_linear_412_2336)"/>
                <path d="M14.7781 5.52295H13.7739C13.5151 5.52295 13.3052 5.73282 13.3052 5.9917C13.3052 6.25057 13.5151 6.46045 13.7739 6.46045H14.7781C15.037 6.46045 15.2469 6.25057 15.2469 5.9917C15.2469 5.73282 15.037 5.52295 14.7781 5.52295Z" fill="url(#paint1_linear_412_2336)"/>
                <path d="M9.25513 1.94166C9.514 1.94166 9.72388 1.73178 9.72388 1.47291V0.46875C9.72388 0.209875 9.514 0 9.25513 0C8.99625 0 8.78638 0.209875 8.78638 0.46875V1.47291C8.78638 1.73178 8.99625 1.94166 9.25513 1.94166Z" fill="url(#paint2_linear_412_2336)"/>
                <path d="M0.890412 11.6853C0.707506 11.8682 0.70735 12.1652 0.890412 12.3482L2.89876 14.3566C3.08176 14.5396 3.37857 14.5397 3.56166 14.3566L4.34716 13.5711L1.67594 10.8999L0.890412 11.6853Z" fill="url(#paint3_linear_412_2336)"/>
                <path d="M9.00607 12.0635L5.7146 13.6124L7.96469 15.8625C8.14769 16.0455 8.44454 16.0456 8.6276 15.8625L10.3849 14.1052C10.5679 13.9222 10.5679 13.6254 10.3849 13.4423L9.00607 12.0635Z" fill="url(#paint4_linear_412_2336)"/>
                <path d="M7.0763 1.64364C6.96837 1.5357 6.81462 1.48711 6.66424 1.51332C6.51387 1.53957 6.38571 1.63739 6.32071 1.77551L2.33887 10.2369L5.01009 12.9081L13.4715 8.92629C13.6096 8.86129 13.7074 8.73311 13.7337 8.58273C13.7599 8.43236 13.7113 8.27864 13.6034 8.1707L7.0763 1.64364Z" fill="url(#paint5_linear_412_2336)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_412_2336" x1="12.7699" y1="1.50635" x2="12.7699" y2="3.44799" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <linearGradient id="paint1_linear_412_2336" x1="14.276" y1="5.52295" x2="14.276" y2="6.46045" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <linearGradient id="paint2_linear_412_2336" x1="9.25513" y1="0" x2="9.25513" y2="1.94166" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <linearGradient id="paint3_linear_412_2336" x1="2.55017" y1="10.8999" x2="2.55017" y2="14.4939" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <linearGradient id="paint4_linear_412_2336" x1="8.11839" y1="12.0635" x2="8.11839" y2="15.9998" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <linearGradient id="paint5_linear_412_2336" x1="8.03977" y1="1.50635" x2="8.03977" y2="12.9081" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4736BE"/>
                <stop offset="0.505208" stop-color="#7E3DC7"/>
                <stop offset="1" stop-color="#973086"/>
                </linearGradient>
                <clipPath id="clip0_412_2336">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
                </svg>
              </div>
            : ''}
            {message.chat_assets?.length > 0 ?
              <>
                {message.file_type === "image" || message.file_type === "video" ?
                  <div className={
                    message.chat_assets.length > 1 ?
                      "uploaded-chat-room-multiple-img-card" : "uploaded-chat-room-single-img-card"
                  }>
                    {message.chat_assets.map((chatAsset, i) =>
                      <SendChatAsset
                        chatAsset={chatAsset}
                        key={i}
                        i={i}
                        length={message.chat_assets.length}
                      />
                    )}
                  </div>
                  : <div className="uploaded-chat-room-audio-card">
                    {message.chat_assets.map((chatAsset, i) =>
                      <AltraChatAudioPlayer src={chatAsset.asset_file} />
                    )}
                  </div>
                }
              </> : null
            }
            {message.message ?
              <h6>{message.message}</h6>
              : null
            }
            <p>
              {message.amount > 0 ?

                message.is_user_paid === 1 ?
                  <span className="text-success">
                    {message.amount_formatted}{" "}
                    <Image
                      className="new-chat-room-time-tick"
                      src={
                        window.location.origin + "/assets/images/new-chat/ppv_paid.svg"
                      }
                    />
                  </span>
                  : <span className="text-danger">
                    {message.amount_formatted}{" "}
                    <Image
                      className="new-chat-room-time-tick"
                      src={
                        window.location.origin + "/assets/images/new-chat/ppv_pending.svg"
                      }
                    />
                  </span>
                : null}

              <span>{message.time_formatted}</span>
            </p>
          </div>
          {/* <div className="new-chat-room-time-tick-card">
            <Image
              className="new-chat-room-time-tick"
              src={
                window.location.origin + "/assets/images/new-chat/msg-tick.svg"
              }
            />
            
          </div> */}
        </div>
      </div >
    </>
  );
}

const SendChatAsset = ({ chatAsset, i, length }) => {
  const dispatch = useDispatch();
  const assetImage = useRef(null);

  const triggerFancyBox = e => {
    e.preventDefault();
    console.log("Ref", assetImage.current);
    if (assetImage.current) {
      assetImage.current.click();
      stopAudio();
    }
  }

  const stopAudio = () => {
    dispatch(changeChatAudio({ src: "" }));
  }

  return (
    chatAsset.file_type === "image" ?
      <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
        <Image
          className="uploaded-chat-room-multiple-img"
          src={chatAsset.asset_file}
          data-fancybox-chat
          ref={assetImage}
          onClick={() => stopAudio()}
        />
        {length > 4 && i === 3 ? <div
          className="uploaded-chat-room-video-icon-sec"
          onClick={triggerFancyBox}>
          <div className="chat-more-sec">+{length - 3}</div>
        </div>
          : null
        }
      </div>
      : <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
        <Image
          ref={assetImage}
          className={`uploaded-chat-room-multiple-img`}
          src={chatAsset.blur_file}
          data-fancybox-chat
          href={chatAsset.asset_file}
          onClick={() => stopAudio()}
        />
        <div
          className="uploaded-chat-room-video-icon-sec cursor-pointer"
          onClick={triggerFancyBox}>
          {length > 4 && i === 3 ?
            <div className="chat-more-sec">+{length - 3}</div>
            : <Image
              src={window.location.origin + "/assets/images/new-home/icon/video-icon.png"}
              className="uploaded-chat-room-video-icon"
            />
          }
        </div>
      </div>
  );
}

export default SendChat;