import {
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_FAILURE,
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_START,
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_FAILURE,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_SUCCESS,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_FAILURE,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_SUCCESS,
  ORDERS_PAYMENT_BY_RAZORPAY_FAILURE,
  ORDERS_PAYMENT_BY_RAZORPAY_START,
  ORDERS_PAYMENT_BY_RAZORPAY_SUCCESS,
  POST_PAYMENT_BY_RAZORPAY_FAILURE,
  POST_PAYMENT_BY_RAZORPAY_START,
  POST_PAYMENT_BY_RAZORPAY_SUCCESS,
  RAZORPAY_ADD_WALLET_MONEY_FAILURE,
  RAZORPAY_ADD_WALLET_MONEY_START,
  RAZORPAY_ADD_WALLET_MONEY_SUCCESS,
  RAZORPAY_CREATE_ORDER_FAILURE,
  RAZORPAY_CREATE_ORDER_START,
  RAZORPAY_CREATE_ORDER_SUCCESS,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_FAILURE,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_SUCCESS,
  TIPS_PAYMENT_BY_RAZORPAY_FAILURE,
  TIPS_PAYMENT_BY_RAZORPAY_START,
  TIPS_PAYMENT_BY_RAZORPAY_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_FAILURE,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_START,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS
} from "../actions/ActionConstant";

const initialState = {
  initilizeRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  addWalletRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subscriptionRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null
  },
  postRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null
  },
  tipsRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  liveRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  ordersRazorPay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  chatRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  videoCallRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  audioCallRazorpay: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const RazorpayReducer = (state = initialState, action) => {
  switch (action.type) {
    case RAZORPAY_CREATE_ORDER_START:
      return {
        ...state,
        initilizeRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case RAZORPAY_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        initilizeRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RAZORPAY_CREATE_ORDER_FAILURE:
      return {
        ...state,
        initilizeRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RAZORPAY_ADD_WALLET_MONEY_START:
      return {
        ...state,
        addWalletRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      }
    case RAZORPAY_ADD_WALLET_MONEY_SUCCESS:
      return {
        ...state,
        addWalletRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case RAZORPAY_ADD_WALLET_MONEY_FAILURE:
      return {
        ...state,
        addWalletRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        subscriptionRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case SUBSCRIPTION_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        subscriptionRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SUBSCRIPTION_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        subscriptionRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case POST_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        postRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case POST_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        postRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case POST_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        postRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case TIPS_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        tipsRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case TIPS_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        tipsRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case TIPS_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        tipsRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        liveRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        liveRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        liveRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case ORDERS_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        ordersRazorPay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case ORDERS_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        ordersRazorPay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case ORDERS_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        ordersRazorPay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        chatRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case CHAT_ASSETS_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        chatRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case CHAT_ASSETS_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        chatRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case VIDEO_CALL_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        videoCallRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case VIDEO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        videoCallRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case VIDEO_CALL_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        videoCallRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case AUDIO_CALL_PAYMENT_BY_RAZORPAY_START:
      return {
        ...state,
        audioCallRazorpay: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case AUDIO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS:
      return {
        ...state,
        audioCallRazorpay: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case AUDIO_CALL_PAYMENT_BY_RAZORPAY_FAILURE:
      return {
        ...state,
        audioCallRazorpay: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    default:
      return state;
  }
}
export default RazorpayReducer;