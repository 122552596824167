import {
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_FAILURE,
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_START,
  AUDIO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_FAILURE,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START,
  CHAT_ASSETS_PAYMENT_BY_RAZORPAY_SUCCESS,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_FAILURE,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START,
  LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_SUCCESS,
  ORDERS_PAYMENT_BY_RAZORPAY_FAILURE,
  ORDERS_PAYMENT_BY_RAZORPAY_START,
  ORDERS_PAYMENT_BY_RAZORPAY_SUCCESS,
  POST_PAYMENT_BY_RAZORPAY_FAILURE,
  POST_PAYMENT_BY_RAZORPAY_START,
  POST_PAYMENT_BY_RAZORPAY_SUCCESS,
  RAZORPAY_ADD_WALLET_MONEY_FAILURE,
  RAZORPAY_ADD_WALLET_MONEY_START,
  RAZORPAY_ADD_WALLET_MONEY_SUCCESS,
  RAZORPAY_CREATE_ORDER_FAILURE,
  RAZORPAY_CREATE_ORDER_START,
  RAZORPAY_CREATE_ORDER_SUCCESS,
  SUBSCRIPTION_AUTO_RENEWAL_FAILURE,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START,
  SUBSCRIPTION_PAYMENT_BY_RAZORPAY_SUCCESS,
  TIPS_PAYMENT_BY_RAZORPAY_FAILURE,
  TIPS_PAYMENT_BY_RAZORPAY_START,
  TIPS_PAYMENT_BY_RAZORPAY_SUCCESS,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_FAILURE,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_START,
  VIDEO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS,
} from "./ActionConstant";


export const razorpayCreateOrderStart = data => ({ type: RAZORPAY_CREATE_ORDER_START, data })
export const razorpayCreateOrderSuccess = data => ({ type: RAZORPAY_CREATE_ORDER_SUCCESS, data })
export const razorpayCreateOrderFailure = error => ({ type: RAZORPAY_CREATE_ORDER_FAILURE, error })

export const razorpayAddWalletMoneyStart = data => ({ type: RAZORPAY_ADD_WALLET_MONEY_START, data })
export const razorpayAddWalletMoneySuccess = data => ({ type: RAZORPAY_ADD_WALLET_MONEY_SUCCESS, data })
export const razorpayAddWalletMoneyFailure = error => ({ type: RAZORPAY_ADD_WALLET_MONEY_FAILURE, error })

export const subscriptionPaymentByRazorpayStart = data => ({ type: SUBSCRIPTION_PAYMENT_BY_RAZORPAY_START, data })
export const subscriptionPaymentByRazorpaySuccess = data => ({ type: SUBSCRIPTION_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const subscriptionPaymentByRazorpayFailure = error => ({ type: SUBSCRIPTION_AUTO_RENEWAL_FAILURE, error })

export const postPaymentByRazorpayStart = data => ({ type: POST_PAYMENT_BY_RAZORPAY_START, data })
export const postPaymentByRazorpaySuccess = data => ({ type: POST_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const postPaymentByRazorPayFailure = error => ({ type: POST_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const tipsPaymentByRazorpayStart = data => ({ type: TIPS_PAYMENT_BY_RAZORPAY_START, data })
export const tipsPaymentByRazorpaySuccess = data => ({ type: TIPS_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const tipsPaymentByRazorpayFailure = error => ({ type: TIPS_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const liveVideosPaymentByRazorpayStart = data => ({ type: LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_START, data })
export const liveVideosPaymentByRazorpaySuccess = data => ({ type: LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const liveVideosPaymentByRazorpayFailure = error => ({ type: LIVE_VIDEOS_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const ordersPaymentByRazorpayStart = data => ({ type: ORDERS_PAYMENT_BY_RAZORPAY_START, data })
export const ordersPaymentByRazorpaySuccess = data => ({ type: ORDERS_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const ordersPaymentByRazorpayFailure = error => ({ type: ORDERS_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const chatAssetsPaymentByRazorpayStart = data => ({ type: CHAT_ASSETS_PAYMENT_BY_RAZORPAY_START, data })
export const chatAssetsPaymentByRazorpaySuccess = data => ({ type: CHAT_ASSETS_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const chatAssetsPaymentByRazorpayFailure = error => ({ type: CHAT_ASSETS_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const videoCallPaymentByRazorpayStart = data => ({ type: VIDEO_CALL_PAYMENT_BY_RAZORPAY_START, data })
export const videoCallPaymentByRazorpaySuccess = data => ({ type: VIDEO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const videoCallPaymentByRazorpayFailure = error => ({ type: VIDEO_CALL_PAYMENT_BY_RAZORPAY_FAILURE, error })

export const audioCallPaymentByRazorpayStart = data => ({ type: AUDIO_CALL_PAYMENT_BY_RAZORPAY_START, data })
export const audioCallPaymentByRazorpaySuccess = data => ({ type: AUDIO_CALL_PAYMENT_BY_RAZORPAY_SUCCESS, data })
export const audioCallPaymentByRazorpayFailure = error => ({ type: AUDIO_CALL_PAYMENT_BY_RAZORPAY_FAILURE, error })