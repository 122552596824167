import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";

const LiveSecCard = (props) => {
    return (
        <>
            <div className="profile-tab-sec">
                <Row>
                    <Col md={12}>
                        <div className="live-stream-box">
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-2.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-live-status-sec">
                                        <span className="dots"></span>
                                        Live
                                    </div>
                                    <div className="most-popular-list-sec">
                                        <ul className="list-unstyled">
                                            <Media as="li" className="total-count">
                                                <div className="live-list-count">
                                                    <span>1.25k</span>
                                                </div>
                                            </Media>
                                            <Media as="li" className="user-1">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-1.png"
                                                    }
                                                />
                                            </Media>
                                            <Media as="li" className="user-2">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-2.png"
                                                    }
                                                />
                                            </Media>
                                            <Media as="li" className="user-3">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-3.png"
                                                    }
                                                />
                                            </Media>
                                        </ul>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card live-stream-paid-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-2.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-live-status-sec">
                                        <span className="dots"></span>
                                        Live
                                    </div>
                                    <div className="most-popular-list-sec">
                                        <ul className="list-unstyled">
                                            <Media as="li" className="total-count">
                                                <div className="live-list-count">
                                                    <span>1.25k</span>
                                                </div>
                                            </Media>
                                            <Media as="li" className="user-1">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-1.png"
                                                    }
                                                />
                                            </Media>
                                            <Media as="li" className="user-2">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-2.png"
                                                    }
                                                />
                                            </Media>
                                            <Media as="li" className="user-3">
                                                <Image
                                                    className="live-list-img"
                                                    src={
                                                        window.location.origin + "/assets/images/live-streaming/live-list-3.png"
                                                    }
                                                />
                                            </Media>
                                        </ul>
                                    </div>
                                    <div className="paid-live-stream-footer">
                                        <Button className="paid-btn">
                                            PAID
                                        </Button>
                                        <p>45 Tokens</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                            <Link to="#">
                                <div className="live-stream-card">
                                    <div className="live-stream-img-sec">
                                        <Image
                                            className="live-stream-img"
                                            src={
                                                window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="live-stream-date-time-sec">
                                        <h6>12 Dec</h6>
                                        <p>10:21 PM</p>
                                    </div>
                                    <div className="join-live-token-sec">
                                        Join Live Using 45 Tokens
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LiveSecCard;