import {
  FETCH_TRAINER_AVAILABILITES_FAILURE,
  FETCH_TRAINER_AVAILABILITES_START,
  FETCH_TRAINER_AVAILABILITES_SUCCESS,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_FAILURE,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_START,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_SUCCESS,
  SAVE_TRAINER_AVAILABILITES_FAILURE,
  SAVE_TRAINER_AVAILABILITES_START,
  SAVE_TRAINER_AVAILABILITES_SUCCESS
} from "../actions/ActionConstant";


const initialState = {
  trainerAvailabilites: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  saveTrainerAvailability: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const AvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRAINER_AVAILABILITES_START:
      return {
        ...state,
        trainerAvailabilites: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_TRAINER_AVAILABILITES_SUCCESS:
      return {
        ...state,
        trainerAvailabilites: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      };
    case FETCH_TRAINER_AVAILABILITES_FAILURE:
      return {
        ...state,
        trainerAvailabilites: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SAVE_TRAINER_AVAILABILITES_START:
      return {
        ...state,
        saveTrainerAvailability: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading... Please wait"
        }
      }
    case SAVE_TRAINER_AVAILABILITES_SUCCESS:
      return {
        ...state,
        saveTrainerAvailability: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case SAVE_TRAINER_AVAILABILITES_FAILURE:
      return {
        ...state,
        saveTrainerAvailability: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_TRAINER_AVAILABILITIES_FOR_USER_START:
      return {
        ...state,
        trainerAvailabilites: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading.. Please wait",
        }
      };
    case FETCH_TRAINER_AVAILABILITIES_FOR_USER_SUCCESS:
      return {
        ...state,
        trainerAvailabilites: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_TRAINER_AVAILABILITIES_FOR_USER_FAILURE:
      return {
        ...state,
        trainerAvailabilites: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    default:
      return state;
  }
};

export default AvailabilityReducer;