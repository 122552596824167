import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import AddCardModalSec from './AddCardModalSec';
import PaymentMethodCard from './PaymentMethodCard';
import { connect } from 'react-redux';
import { translate, t } from 'react-multi-lang';
import WalletAmountSec from './WalletAmountSec';
import {
  addMoneyViaCardStart,
  addMoneyViaPaypalStart,
  fetchWalletDetailsStart
} from '../../../store/actions/WalletAction';
import {
  razorpayAddWalletMoneyStart,
  razorpayCreateOrderStart
} from '../../../store/actions/RazorpayAction';
import { getErrorNotificationMessage } from '../../helper/NotificationMessage';
import { createNotification } from 'react-redux-notify';
import { fetchAllTransactionStart } from '../../../store/actions/TransactionAction';
import configuration from "react-global-configuration";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const AddWalletAmountModal = (props) => {
  const nullData = ["", null, undefined, "light"];
  const [skipRender, setSkipRender] = useState(true);
  const [paymentType, setPaymentType] = useState("CARD");
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [amount, setAmount] = useState(1);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        addMoneyViaCardStart({
          amount: amount,
          user_card_id: selectedCard,
        })
      );
    else if (paymentType === "RAZOR_PAY") {
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
      } else {
        props.dispatch(razorpayCreateOrderStart({
          amount: amount
        }));
      }
    }
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        addMoneyViaPaypalStart({
          payment_id: payment.paymentID,
          amount: amount,
        })
      );
    }, 1000);
    props.dispatch(fetchWalletDetailsStart());
    props.dispatch(fetchAllTransactionStart());
    props.closepaymentsModal();
  };

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  useEffect(() => {
    if (!skipRender && !props.addWalletMoneyRazorpay.loading && Object.keys(props.addWalletMoneyRazorpay.data).length > 0) {
      const options = {
        key: configuration.get("configData.RAZOR_KEY"),
        currency: props.addWalletMoneyRazorpay.data.currency,
        amount: props.addWalletMoneyRazorpay.data.amount.toString(),
        order_id: props.addWalletMoneyRazorpay.data.id,
        name: 'Wallet Reacharge',
        description: 'Add amount to wallet',
        handler: async (response) => {
          try {
            const payment_id = response.razorpay_payment_id;
            const inputData = { amount: amount, payment_id }
            props.dispatch(razorpayAddWalletMoneyStart(inputData));
          } catch (err) {
            console.log(err);
          }
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [props.addWalletMoneyRazorpay]);

  useEffect(() => {
    if (!skipRender && !props.razorpayComplete.loading && Object.keys(props.razorpayComplete.data).length > 0) {
      props.dispatch(fetchWalletDetailsStart());
      props.dispatch(fetchAllTransactionStart());
      props.closepaymentsModal();
    }
  }, [props.razorpayComplete]);

  useEffect(() => {
    if (!skipRender && !props.addAmount.loading && Object.keys(props.addAmount.successData).length > 0) {
      props.dispatch(fetchWalletDetailsStart());
      props.dispatch(fetchAllTransactionStart());
      props.closepaymentsModal();
    }
    setSkipRender(false);
  }, [props.addAmount]);

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
            }`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Body className="wallet-card-body">
            <Button className="modal-close"
              onClick={() => props.closepaymentsModal()}>
              <i className="fa fa-times" />
            </Button>
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                  showWallet={false}
                />
                <Col md={12} xl={5}>
                  {showAddCard ?
                    <AddCardModalSec
                      setShowAddCard={setShowAddCard}
                    />
                    : <WalletAmountSec
                      amount={amount}
                      setAmount={setAmount}
                      paymentType={paymentType}
                      handleSubmit={handleSubmit}
                      paypalOnSuccess={paypalOnSuccess}
                      paypalOnError={paypalOnError}
                      paypalOnCancel={paypalOnCancel}
                      buttonDisable={
                        props.addAmount.buttonDisable ||
                        props.addWalletMoneyRazorpay.buttonDisable ||
                        props.razorpayComplete.buttonDisable
                      }
                      loadingButtonContent={
                        props.addAmount.loadingButtonContent ?
                          props.addAmount.loadingButtonContent
                          : props.addWalletMoneyRazorpay.loadingButtonContent ?
                            props.addWalletMoneyRazorpay.loadingButtonContent
                            : props.razorpayComplete.loadingButtonContent ?
                              props.razorpayComplete.loadingButtonContent
                              : null
                      }
                    />
                  }
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  addAmount: state.wallet.addMoneyInput,
  addWalletMoneyRazorpay: state.razorPay.initilizeRazorpay,
  razorpayComplete: state.razorPay.addWalletRazorpay,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddWalletAmountModal));