import {
  FETCH_TRAINER_AVAILABILITES_FAILURE,
  FETCH_TRAINER_AVAILABILITES_START,
  FETCH_TRAINER_AVAILABILITES_SUCCESS,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_FAILURE,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_START,
  FETCH_TRAINER_AVAILABILITIES_FOR_USER_SUCCESS,
  SAVE_TRAINER_AVAILABILITES_FAILURE,
  SAVE_TRAINER_AVAILABILITES_START,
  SAVE_TRAINER_AVAILABILITES_SUCCESS
} from "./ActionConstant"


export const fetchTrainerAvailabilitesStart = data => ({ type: FETCH_TRAINER_AVAILABILITES_START, data })
export const fetchTrainerAvailabilitesSuccess = data => ({ type: FETCH_TRAINER_AVAILABILITES_SUCCESS, data })
export const fetchTrainerAvailabilitesFailure = error => ({ type: FETCH_TRAINER_AVAILABILITES_FAILURE, error })

export const saveTrainerAvailabilitesStart = data => ({ type: SAVE_TRAINER_AVAILABILITES_START, data })
export const saveTrainerAvailabilitesSuccess = data => ({ type: SAVE_TRAINER_AVAILABILITES_SUCCESS, data })
export const saveTrainerAvailabilitesFailure = error => ({ type: SAVE_TRAINER_AVAILABILITES_FAILURE, error })

export const fetchTrainerAvailabilitesForUserStart = data => ({ type: FETCH_TRAINER_AVAILABILITIES_FOR_USER_START, data })
export const fetchTrainerAvailabilitesForUserSuccess = data => ({ type: FETCH_TRAINER_AVAILABILITIES_FOR_USER_SUCCESS, data })
export const fetchTrainerAvailabilitesForUserFailure = error => ({ type: FETCH_TRAINER_AVAILABILITIES_FOR_USER_FAILURE, error })