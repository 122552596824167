import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import "./ScheduleAvailability.css";
import UpcomingGoLiveModal from './UpcomingGoLiveModal';

const UpcomingPopularLiveIndex = (props) => {

  const [upcomingGoLive, setUpcomingGoLive] = useState(false);

  const closeUpcomingGoLiveModal = () => {
    setUpcomingGoLive(false);
  };

  return (
    <>
      <div className="upcoming-popular-live-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="upcoming-popular-live-header-sec">
                <Link to="#" className="upcoming-go-live-btn" onClick={() => setUpcomingGoLive(true)}>
                  Go Live
                </Link>
                <Link to="#" className="upcoming-on-live-btn">
                  Live History
                </Link>
                <Link to="#" className="upcoming-on-live-btn">
                  On Live
                </Link>
              </div>
              <h2 className="sub-title-1">Most Popular</h2>
              <div className="upcoming-live-box">
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-2.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-live-status-sec">
                      <span className="dots"></span>
                      Live
                    </div>
                    <div className="most-popular-list-sec">
                      <ul className="list-unstyled">
                        <Media as="li" className="total-count">
                          <div className="live-list-count">
                            <span>1.25k</span>
                          </div>
                        </Media>
                        <Media as="li" className="user-1">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-1.png"
                            }
                          />
                        </Media>
                        <Media as="li" className="user-2">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-2.png"
                            }
                          />
                        </Media>
                        <Media as="li" className="user-3">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-3.png"
                            }
                          />
                        </Media>
                      </ul>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="paid-live-stream-footer">
                      <Button className="paid-btn">
                        PAID
                      </Button>
                      <p>45 Tokens</p>
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-date-time-sec">
                      <h6>12 Dec</h6>
                      <p>10:21 PM</p>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="join-live-token-sec">
                      Join Live Using 45 Tokens
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-date-time-sec">
                      <h6>12 Dec</h6>
                      <p>10:21 PM</p>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="join-live-token-sec">
                      Join Live Using 45 Tokens
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-date-time-sec">
                      <h6>12 Dec</h6>
                      <p>10:21 PM</p>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="join-live-token-sec">
                      Join Live Using 45 Tokens
                    </div>
                  </div>
                </Link>
              </div>
              <h2 className="sub-title-1">Recomended For You</h2>
              <div className="upcoming-live-box">
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-2.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-live-status-sec">
                      <span className="dots"></span>
                      Live
                    </div>
                    <div className="most-popular-list-sec">
                      <ul className="list-unstyled">
                        <Media as="li" className="total-count">
                          <div className="live-list-count">
                            <span>1.25k</span>
                          </div>
                        </Media>
                        <Media as="li" className="user-1">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-1.png"
                            }
                          />
                        </Media>
                        <Media as="li" className="user-2">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-2.png"
                            }
                          />
                        </Media>
                        <Media as="li" className="user-3">
                          <Image
                            className="live-list-img"
                            src={
                              window.location.origin + "/assets/images/live-streaming/live-list-3.png"
                            }
                          />
                        </Media>
                      </ul>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="paid-live-stream-footer">
                      <Button className="paid-btn">
                        PAID
                      </Button>
                      <p>45 Tokens</p>
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-date-time-sec">
                      <h6>12 Dec</h6>
                      <p>10:21 PM</p>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="join-live-token-sec">
                      Join Live Using 45 Tokens
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-1.png"
                        }
                      />
                    </div>
                    <div className="upcoming-live-date-time-sec">
                      <h6>12 Dec</h6>
                      <p>10:21 PM</p>
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="join-live-token-sec">
                      Join Live Using 45 Tokens
                    </div>
                  </div>
                </Link>
                <Link to="#">
                  <div className="upcoming-live-card">
                    <div className="upcoming-live-img-sec">
                      <Image
                        className="upcoming-live-img"
                        src={
                          window.location.origin + "/assets/images/live-stream-card/picture-2.png"
                        }
                      />
                    </div>
                    <div className="upcoming-popular-user-info-card">
                      <div className="upcoming-popular-user-info">
                        <Image
                          className="upcoming-popular-user-img"
                          src={
                            window.location.origin + "/assets/images/live-streaming/user-img.png"
                          }
                        />
                        <div className="upcoming-popular-user-details">
                          <h4>Isabella Olivia
                            <span>
                              <Image
                                className="sidebar-verified-icon"
                                src={
                                  window.location.origin + "/assets/images/new-home/verified-icon.svg"
                                }
                              />
                            </span>
                          </h4>
                          <Link to="#" className="upcoming-popular-user-name">
                            @isabellaolivia
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="paid-live-stream-footer">
                      <Button className="paid-btn">
                        PAID
                      </Button>
                      <p>45 Tokens</p>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <UpcomingGoLiveModal
        upcomingGoLive={upcomingGoLive}
        closeUpcomingGoLiveModal={closeUpcomingGoLiveModal}
        setUpcomingGoLive={setUpcomingGoLive}
      />
    </>
  )
}

export default UpcomingPopularLiveIndex;